import "./color-picker-collection.scss";
import React, { useEffect } from "react";

import { Box, Button } from "@mui/material";

type ColorPickerProps = {
  variants: Array<{
    idattach: string,
    file_name: string,
    s3_url: string
  }> | Array<{
    id: string,
    s3_url: string
  }>;
};


export const ColorPickerCollection = ({ variants, onVariantClick }: ColorPickerProps & { onVariantClick: (id: string) => void }) => {

  const MAX_IMAGES = 4;
  const visibleVariants = variants.slice(0, MAX_IMAGES);
  const extraCount = variants.length - MAX_IMAGES;

  const handleVariantClick = (e: React.MouseEvent, variantId: string) => {
    e.stopPropagation(); // Interrompe la propagazione dell'evento
    e.preventDefault();
    onVariantClick(variantId); // Chiama la funzione passata dal padre
  };

  return (
    <React.Fragment>
      <div className="flex w-full items-center justify-between mt-[8px]">
        <div className="flex items-center gap-[10px]">
          {visibleVariants.map((variant: any, index: number) => {
            return (
              // <div className={`flex w-[35px] h-[35px] md:w-[40px] md:h-[40px] md:min-w-[40px] aspect-square rounded-full overflow-hidden cursor-pointer ${ props.selected === color.id ? 'border-[3px] border-cta' : 'border-[3px] border-black' }`}>
              <div key={index} onClick={(e) => handleVariantClick(e, variant.id)} className={`flex w-[22px] h-[22px] md:w-[30px] md:h-[30px] md:min-w-[30px] aspect-square rounded-full overflow-hidden cursor-pointer border-2 border-secondary`}>
                <div className="flex w-full h-full">
                  <img className="w-full h-full object-cover object-center" src={variant.s3_url} alt="" />
                  {/* TODO - far apparire tendina con il nome */}
                </div>
              </div>
            );
          })}
        </div>
        {extraCount > 0 && (
          <div
            className={`flex`}
          >
            <span className="text-base font-semibold">+{extraCount}</span>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
