import "./size-picker.scss";
import React, { useRef } from "react";

import { Button } from "@mui/material";

export const SizePicker = (props: { sizes: Array<any>, selected: number, loading: boolean, click: (id: string) => void }) => {

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  // Funzione per calcolare la larghezza del testo
  const getTextWidth = (text: string, font: string): number => {
    const canvas = canvasRef.current || document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      context.font = font;
      return context.measureText(text).width;
    }
    return 0;
  };

  return (
    <React.Fragment>
      {props.sizes.map((size: any) => {
        <canvas ref={canvasRef} style={{ display: 'none' }} />
        const font = '12px Arial'; // Definisci lo stile del font (corrispondente al CSS del bottone)
        const textWidth = getTextWidth(size.text, font);
        const minWClass = textWidth <= 35 ? 'min-w-[35px] md:min-w-[40px]' : 'min-w-fit md:min-w-[40px]';
        return (
          <>
          <button disabled={props.loading || size.quantita <= 0}
          className={`flex text-white items-center justify-center text-[12px] h-[35px] px-[5px] md:h-[40px] md:px-[8px] ${minWClass} rounded-[20px] cursor-pointer disabled:text-secondary ${ props.selected === size.value ? 'border-[3px] border-cta' : 'border-[3px] border-secondary' }`}
          onClick={() => {
            if (props.selected !== size.value)  {
              props.click(size)
            }
          }}>
            <p>{size.text}</p>
          </button>
          </>
          // <Button
          //   key={size.id}
          //   onClick={() => {
          //     if (props.selected !== size.value)  {
          //       props.click(size)
          //     }
          //   }}
          //   disabled={props.loading || size.quantita <= 0}
          //   className="w-[30px] h-[30px] md:!w-[40px] md:h-[40px] md:min-w-[40px]"
          //   sx={{
          //     padding: 'unset 5px',
          //     marginX: '5px',
          //     marginBottom: '10px',
          //     fontWeight: 'bold',
          //     borderRadius: '50%',
          //     background: props.selected === size.value ? '#FFCE00' : '#f7f8fa',
          //     color: props.selected === size.value ? '#000' : '#777',
          //     // minWidth: '40px',
          //     // minHeight: '40px',
          //     '&:hover': {
          //       background: '#FFCE00',
          //       color: '#000',
          //     },
          //     '&:disabled': {
          //       color: props.selected === size.value ? '#000' : '#ccc',
          //     }
          //   }}>
          //     {size.text}
          // </Button>
        );
      })}
    </React.Fragment>
  );
}
