import "./qty-picker.scss";
import React from "react";

import { Box, Grid, IconButton, Typography } from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';


export const QtyPicker = (props: { qty: number, disabled?: boolean, remove?: () => void, add?: () => void }) => {

    return (
        <div className="flex">
            <IconButton onClick={props.remove} disabled={props.disabled}>
                <RemoveIcon fontSize="small"></RemoveIcon>
            </IconButton>
            <span className="flex w-[60px] bg-[white] text-black justify-center items-center rounded-2xl">{props.qty}</span>
            <IconButton onClick={props.add} disabled={props.disabled} sx={{}}>
                <AddIcon fontSize="small"></AddIcon>
            </IconButton>
        </div>
    );
}
