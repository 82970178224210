import "./infinite-product-slider.scss";
import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import { useTheme } from "@mui/material";
import { IProduct, Product } from "../product/product";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

export const InfiniteProductsSlider = (props: {
    products: IProduct[],
}) => {

    const theme = useTheme();
    const products = props.products;
    const [width, setWidth] = React.useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    let slidesPartnersCount: number;
    let slideSpace: number = 50;

    if (width >= 1600) {
        slidesPartnersCount = 6;
    } else if (width >= 1400) {
        slidesPartnersCount = 6;
    } else if (width >= 1100) {
        slidesPartnersCount = 4;
    } else if (width >= 720) {
        slidesPartnersCount = 4;
    } else if (width >= 480) {
        slidesPartnersCount = 2;
        slideSpace = 30
    } else {
        slidesPartnersCount = 2;
        slideSpace = 30
    }

    return (
        <React.Fragment>
            <div className="flex w-full relative">
                {products && products.length > 0 &&
                    <Swiper
                        slidesPerView={slidesPartnersCount}
                        initialSlide={Math.floor(products.length / 2)}
                        spaceBetween={slideSpace}
                        centeredSlides={true}
                        loop={true} // Abilita il loop infinito
                        autoplay={{
                            delay: 3000, // Velocità dell'autoplay in millisecondi
                            disableOnInteraction: false, // L'autoplay continua anche dopo l'interazione
                            pauseOnMouseEnter: true, // L'autoplay si ferma quando si passa con il mouse
                        }}
                        navigation={{
                            nextEl: ".swiper-btn-next", // Classe per il pulsante "avanti"
                            prevEl: ".swiper-btn-prev", // Classe per il pulsante "indietro"
                        }}
                        modules={[Navigation, Autoplay]}
                    >
                        {products?.map((product: any, index: number) => (
                            <SwiperSlide key={index}>
                                <Product product={product} index={index} animate={true}></Product>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                }
                <div className="swiper-btn-prev"><NavigateBeforeIcon className="text-cta" /></div>
                <div className="swiper-btn-next"><NavigateNextIcon className="text-cta" /></div>
            </div>
        </React.Fragment>
    );
}
