import React, { useEffect } from "react";
import {
    List,
    ListItem,
    ListItemButton,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

type SubcategoryData = {
    id: string;
    nome_categoria: string;
    path: string;
};

type CategoryData = {
    text: string;
    path: string;
    items?: Array<CategoryData>;
    subcategories?: Array<SubcategoryData>;
    sub?: Array<CategoryData>;
};

type ButtonData = {
    title?: string;
    items: Array<CategoryData>;
    path: string;
};

const NoTitleFilters: React.FC<{ data: ButtonData[], activeCategory?: string | null, onCategoryClick?: any }> = ({ data, activeCategory, onCategoryClick }) => {
    const navigate = useNavigate();

    const navigateTo = (path: string) => {
        navigate(path);
    };

    useEffect(() => {
    }, [data]);

    return (
        <li className="flex flex-col w-full md:py-0 md:ps-0">
            {data.map((button, index) => (
                <>
                    {button.items.map((subCategory, subIndex) => {
                        const hasNestedItems =
                            (subCategory.items && subCategory.items.length > 0) ||
                            (subCategory.subcategories && subCategory.subcategories.length > 0) ||
                            (subCategory.sub && subCategory.sub.length > 0);

                        return hasNestedItems ? (
                            // Se ci sono `items`, `subcategories`, o `sub`, crea un accordion
                            <Accordion
                                key={`${index}-${subIndex}`}
                                sx={{
                                    backgroundColor: "transparent",
                                    color: "#fff",
                                    borderRadius: "0 !important",
                                    boxShadow: "none",
                                    padding: "0",
                                    marginY: '5px'
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<AddIcon />}
                                    className="!m-0 !p-0 plus-icon"
                                    sx={{
                                        minHeight: "0 !important",
                                        fontSize: "16px",
                                        textTransform: "uppercase",
                                        margin: 0,
                                    }}
                                >
                                    <Typography className={`category-item ${activeCategory === subCategory.text ? "text-cta" : ""}`} sx={{ minHeight: "0 !important", margin: 0 }} onClick={() => {
                                        if (subCategory.path) {
                                            navigateTo(subCategory.path);
                                            onCategoryClick?.(subCategory.text);
                                        }
                                    }}>
                                        {subCategory.text}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{
                                        minHeight: "0 !important",
                                        paddingY: 0,
                                    }}
                                >
                                    <List
                                        sx={{
                                            minHeight: "0 !important",
                                            padding: 0,
                                            paddingY: "5px",
                                        }}
                                    >
                                        {subCategory.items?.map((item, itemIndex) => (
                                            <ListItem key={itemIndex}>
                                                <ListItemButton
                                                    onClick={() => {
                                                        navigateTo(item.path);
                                                        onCategoryClick?.(item.text);
                                                    }}
                                                >
                                                    <span
                                                        className={`category-item my-[5px] ${activeCategory === item.text ? "text-cta" : ""
                                                            }`}
                                                    >
                                                        {item.text}
                                                    </span>
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                        {subCategory.subcategories?.map((subcategory, subIndex) => (
                                            <ListItem key={subIndex}>
                                                <ListItemButton
                                                    onClick={() => {
                                                        navigateTo(subcategory.path);
                                                        onCategoryClick?.(subcategory.nome_categoria);
                                                    }}
                                                >
                                                    <span
                                                        className={`category-item my-[5px] ${activeCategory === subcategory.nome_categoria
                                                            ? "text-cta"
                                                            : ""
                                                            }`}
                                                    >
                                                        {subcategory.nome_categoria}
                                                    </span>
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                        {subCategory.sub?.map((subItem, subItemIndex) => (
                                            <ListItem key={subItemIndex} sx={{
                                                minHeight: '0 !important',
                                                // background: 'blue',
                                                padding: 0,
                                            }}>
                                                <ListItemButton sx={{
                                                    // background: 'blue',
                                                    padding: 0,
                                                }}
                                                    onClick={() => {
                                                        navigateTo(subItem.path);
                                                        onCategoryClick?.(subItem.text);
                                                    }}
                                                >
                                                    <span
                                                        className={`category-item my-[5px] ${activeCategory === subItem.text ? "text-cta" : ""
                                                            }`}
                                                    >
                                                        {subItem.text}
                                                    </span>
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        ) : (
                            // Altrimenti, crea un pulsante diretto
                            <ListItem key={subIndex}>
                                <ListItemButton
                                    onClick={() => {
                                        navigateTo(subCategory.path);
                                        onCategoryClick?.(subCategory.text);
                                    }}
                                >
                                    <span
                                        className={`category-item ${activeCategory === subCategory.text ? "text-cta" : ""
                                            }`}
                                    >
                                        {subCategory.text}
                                    </span>
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </>
            ))}
        </li>
    );
};

export default NoTitleFilters;