import "./contact-form.scss";
import React from "react";
import { Box, Button, Checkbox, FormControlLabel, Grid, Typography, useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import { CheckboxButtonGroup, FormContainer, TextFieldElement } from "react-hook-form-mui";
import ReCaptcha from "@matt-block/react-recaptcha-v2";
import { BButton, ButtonType } from "../b-button/b-button";


export const ContactForm = (props: { formId: string | number | null, additionalData?: any, version?: 'dark' | 'white', complete: (data: any) => void }) => {

  const [recaptcha, setRecaptcha] = React.useState(false);

  const theme = useTheme();
  const formContext = useForm<{
    nome: string,
    cognome: string,
    email: string,
    cellulare: string,
    messaggio: string,
    'g-recaptcha-response': string
  }>();

  const validation = {
    required: 'Campo obbligatorio'
  };

  const handleSubmit = (data: any) => {
    if (props.additionalData) {
      data = { ...data, ...props.additionalData };
    }
    data = { ...data, form_id: props.formId };
    props.complete(data);
    formContext.reset();
  }

  const [checked, setChecked] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <React.Fragment>
      <Box className="form-container" sx={{
        width: "95%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        // '& .MuiTextField-root.half': {
        //   m: '1%',
        //   width: '48%',
        //   backgroundColor: '#fff',
        // },
        // '& .MuiTextField-root.full': {
        //   m: '1%',
        //   width: '98%',
        //   backgroundColor: '#fff',
        // },
        // my: 4,
        mx: 'auto'
      }}>
        {/* <Grid item xs={12}>
          <Typography variant="h4" sx={{
            position: 'relative',
            textAlign: 'center',
            textTransform: 'uppercase',
            fontWeight: '600',
            my: 4
          }}>Invia la tua richiesta</Typography>
        </Grid> */}
        {/* <Typography variant="h3" mb={4}>
          Invia la tua richiesta
        </Typography> */}

        <FormContainer
          formContext={formContext}
          onSuccess={handleSubmit}
        >
          <div className="grid w-full grid-cols-2 gap-x-[40px] gap-y-[60px]">
            <TextFieldElement
              required
              focused
              name="nome"
              label="Nome"
              color="warning"
              className="blor-input col-span-2 md:col-span-1"
              variant="outlined"
              validation={validation}
            />
            <TextFieldElement
              required
              focused
              name="cognome"
              label="Cognome"
              color="warning"
              className="blor-input col-span-2 md:col-span-1"
              variant="outlined"
              validation={validation}
            />
            <TextFieldElement
              required
              focused
              name="cellulare"
              label="Telefono"
              color="warning"
              className="blor-input col-span-2 md:col-span-1"
              variant="outlined"
              validation={validation}
            />
            <TextFieldElement
              required
              focused
              name="email"
              label="Email"
              color="warning"
              className="blor-input col-span-2 md:col-span-1"
              variant="outlined"
              validation={validation}
            />
            <TextFieldElement
              required
              focused
              name="messaggio"
              label="Scrivi il tuo Messaggio"
              color="warning"
              className="blor-input col-span-2"
              variant="outlined"
              multiline
              rows={6}
              validation={validation}
            />
            <FormControlLabel className="blor-checkbox col-span-2" control={<Checkbox
              // color="secondary"
              checked={checked}
              onChange={handleChange}
              sx={{
                color: props.version === 'dark' ? '#000' : '#FFCE00',
                '&.Mui-checked': {
                  color: props.version === 'dark' ? '#000' : '#FFCE00',
                },
              }} />} label="*Ho preso visione dell'Informativa Privacy e acconsento al trattamento dei dati personali." />

            {/* <Box mb={4}></Box> */}
            <div className="col-span-2 w-full flex items-center justify-center">
            <ReCaptcha
              siteKey="6LeyCVEfAAAAAJdQtaGB_DCLUq2zbainH8Ne_tFJ"
              theme="dark"
              size="normal"
              onSuccess={(captcha) => {
                setRecaptcha(true);
                formContext.setValue('g-recaptcha-response', captcha);
              }}
              onExpire={() => {
                setRecaptcha(false);
                formContext.setValue('g-recaptcha-response', '');
              }}
              onError={() => console.log('errore')}
            />
            </div>
            <div className="col-span-2 w-full flex items-center justify-center">
              <Button type="submit" disabled={!recaptcha || !checked} sx={{
                color: props.version === 'dark' ? '#FFCE00' : 'primary.main',
                backgroundColor: props.version === 'dark' ? 'primary.main' : '#FFCE00',
                borderRadius: '50px',
                minWidth: '200px',
                minHeight: '48px',
                ':hover': {
                  color: props.version === 'dark' ? '#fff' : 'primary.main',
                  backgroundColor: props.version === 'dark' ? 'primary.main' : '#FFCE00',
                },
                '&.Mui-disabled': {
                  color: props.version === 'dark' ? '#fff' : 'primary.main',
                  backgroundColor: props.version === 'dark' ? 'primary.main' : '#262626',
                }
              }}>Invia</Button>
            </div>
          </div>
        </FormContainer>
      </Box>
    </React.Fragment>
  );
}