import "./product-slider.scss";
import React, { useRef, useState } from "react";
// Import Swiper React components & modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs, Mousewheel, Autoplay, Zoom } from 'swiper';
// Import Swiper styles
import 'swiper/scss';
import "swiper/scss/zoom";
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

export const ProductSlider = (props: { images: Array<{ url: string }> }) => {
  const [activeIndex, setActiveIndex] = useState(0); // Stato per tracciare l'indice attivo
  const [imagesNavSlider, setImagesNavSlider] = useState({} as any);
  const mainSwiperRef = useRef<any>(null); // Riferimento allo swiper principale

  const getExtension = (url: string) => {
    return url.split('.').pop();
  };

  const isMobile = () => {
    return window.innerWidth <= 768; // Considerato mobile se larghezza schermo <= 768px
  };

  const handleThumbnailClick = (index: number) => {
    if (mainSwiperRef.current) {
      mainSwiperRef.current.slideTo(index); // Cambia lo slide attivo
    }
  };

  // TODO - Ripristinare l'hover sulle thumbnails desktop come sul sito in prod
  return (
    <section className="slider max-w-full !h-full">
    {/* <section className="slider max-w-full w-full"> */}
      <div className="slider__flex max-w-full lg:max-w-[450px] !h-full relative">
        <div className="slider__images w-full relative">
          <div className="hidden lg:flex  absolute bottom-0 z-10 right-0 w-full justify-center text-white items-center backdrop-blur-sm bg-black/50">
            Doppio click per lo zoom
          </div>
          <Swiper
            thumbs={{ swiper: imagesNavSlider.swiper }}
            direction="horizontal"
            slidesPerView={1}
            mousewheel={true}
            watchSlidesProgress={true}
            autoplay={{
              delay: 3500,
              disableOnInteraction: true,
            }}
            navigation={{
              nextEl: ".slider__next",
              prevEl: ".slider__prev",
            }}
            breakpoints={{
              0: {
                direction: "horizontal",
              },
              768: {
                direction: "horizontal",
              },
            }}
            className="swiper-container2"
            zoom={true}
            modules={[Navigation, Thumbs, Mousewheel, Autoplay, Zoom]}
            onSwiper={(swiper) => (mainSwiperRef.current = swiper)} // Salva il riferimento
            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)} // Aggiorna l'indice attivo
          >
            {props?.images?.map((slide: any, index: number) => (
              <SwiperSlide key={index}>
                <div className="slider__image">
                  <div className="swiper-zoom-container">
                    {getExtension(slide.url) !== "mp4" ? (
                      <img src={slide.url} alt="" />
                    ) : (
                      <video autoPlay loop muted playsInline height="100%" width="100%">
                        <source src={slide.url} type="video/mp4" />
                      </video>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {/* Thumbnails o Mobile Swiper */}
        <div className="flex w-full md:mt-[24px] absolute bottom-0 z-10 left-0 md:relative ">
          {!isMobile() ? (
            <>
              <span className="slider__prev flex items-center justify-center absolute z-10 top-1/2 left-0 transform -translate-y-1/2 aspect-square backdrop-blur-sm bg-black/50 rounded-full text-white">
                <ChevronLeft fontSize={"large"} />
              </span>
              <Swiper
                onSwiper={(swiper) => {
                  setImagesNavSlider(swiper.el);
                }}
                direction="horizontal"
                spaceBetween={24}
                slidesPerView={2}
                navigation={{
                  nextEl: ".slider__next",
                  prevEl: ".slider__prev",
                }}
                className="swiper-container1"
                modules={[Navigation, Thumbs]}
              >
                {props?.images?.map((slide: any, index: number) => (
                  <SwiperSlide key={index}>
                    <div className="slider__image" onClick={() => handleThumbnailClick(index)}>
                      {getExtension(slide.url) !== "mp4" ? (
                        <img src={slide.url} alt="" />
                      ) : (
                        <img src="https://www.iconpacks.net/icons/1/free-video-icon-818-thumb.png" />
                      )}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <span className="slider__next flex items-center justify-center absolute z-10 top-1/2 right-0 transform -translate-y-1/2 aspect-square backdrop-blur-sm bg-black/50 rounded-full text-white">
                <ChevronRight fontSize={"large"} />
              </span>
            </>
          ) : (
            <div className="flex w-full my-[20px] mx-[40px] backdrop-blur-xl bg-black/40 rounded-full overflow-hidden">
            <Swiper
              spaceBetween={0}
              slidesPerView={props.images.length}
              navigation={false}
              className="swiper-container-mobile flex w-full"
              modules={[Mousewheel]}
            >
                {props?.images?.map((_, index: number) => (
                  <SwiperSlide key={index} onClick={() => handleThumbnailClick(index)}>
                    <span
                      className={`${index === activeIndex ? "bg-black" : "bg-transparent"
                        } flex w-full h-[5px] rounded-full`}/>
                  </SwiperSlide>
                ))}
            </Swiper>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
