import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import NoTitleFilters from "./no-title-filters/no-title-filter";

type SubcategoryData = {
  id: string;
  nome_categoria: string;
  path: string;
};

type CategoryData = {
  text: string;
  path: string;
  items?: Array<CategoryData>;
  subcategories?: Array<SubcategoryData>;
  sub?: Array<CategoryData>;
};

type ButtonData = {
  title?: string;
  items: Array<CategoryData>;
  path: string;
};

const CategoryFilters: React.FC<{ data: ButtonData[], activeCategory?: string | null, onCategoryClick?: any }> = ({ data, activeCategory, onCategoryClick }) => {
  const navigate = useNavigate();
  const [expandedMain, setExpandedMain] = useState<string | false>(false);
  const [expandedSub, setExpandedSub] = useState<string | false>(false);

  const handleMainToggle = (mainCategory: string) => {
    setExpandedMain((prev) => (prev === mainCategory ? false : mainCategory));
  };

  const handleSubToggle = (subCategory: string) => {
    setExpandedSub((prev) => (prev === subCategory ? false : subCategory));
  };

  const navigateTo = (path: string) => {
    navigate(path);
  };

  useEffect(() => {
  }, [data]);

  return (
    <ul className="flex flex-col w-full p-[20px] md:py-0 md:ps-0">
      {/* <span className="text-cta">{activeCategory}</span> */}
      {data.map((button, index) => (
        <React.Fragment key={index}>
          {button.title ? (
            <li>
              <Accordion
                key={index}
                expanded={expandedMain === button.title}
                onChange={() => handleMainToggle(button.title!)}
                sx={{
                  backgroundColor: 'transparent',
                  color: '#fff',
                  borderRadius: '0 !important',
                  boxShadow: 'none',
                  padding: 0,
                  marginY: '5px'
                }}
              >
                <AccordionSummary className="!m-0 plus-icon" expandIcon={<AddIcon />} sx={{
                  minHeight: '0 !important',
                  // background: 'green',
                  fontSize: '16px',
                  textTransform: 'uppercase',
                  margin: 0,
                  padding: 0,
                }}>
                  <Typography className={`category-item ${activeCategory === button.title ? "text-cta" : ""}`} variant="h6" onClick={() => {
                    if (button.path) {
                      navigateTo(button.path);
                      onCategoryClick?.(button.title);
                    }
                  }}
                    sx={{
                      // background: 'cyan',
                      fontSize: '16px',
                      textTransform: 'uppercase',
                    }}>{button.title}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{
                  paddingLeft: 0,
                  // background: 'cyan',
                  minHeight: 0,
                  paddingY: '5px',
                  paddingX: 0
                }}>
                  <List sx={{
                    // background: 'white',
                    paddingY: 0,
                    paddingLeft: 0,
                  }}>
                    {button.items.map((subCategory, subIndex) => {
                      const hasNestedItems =
                        (subCategory.items && subCategory.items.length > 0) ||
                        (subCategory.subcategories &&
                          subCategory.subcategories.length > 0) ||
                        (subCategory.sub && subCategory.sub.length > 0);

                      return hasNestedItems ? (
                        // Se ci sono `items`, `subcategories`, o `sub`, crea un accordion
                        <li>

                          <Accordion
                            key={subIndex}
                            expanded={expandedSub === subCategory.text}
                            onChange={() => handleSubToggle(subCategory.text)}
                            sx={{
                              backgroundColor: 'transparent',
                              color: '#fff',
                              borderRadius: '0 !important',
                              boxShadow: 'none',
                              padding: '0',
                              marginY: '5px'
                            }}
                          >
                            <AccordionSummary className="!m-0 !pe-0 plus-icon" expandIcon={<AddIcon />} sx={{
                              minHeight: '0 !important',
                              // background: 'green',
                              fontSize: '16px',
                              textTransform: 'uppercase',
                              margin: 0,
                            }}>
                              <Typography className={`category-item ${activeCategory === subCategory.text ? "text-cta" : ""}`} onClick={() => {
                                        if (subCategory.path) {
                                            navigateTo(subCategory.path);
                                            onCategoryClick?.(subCategory.text);
                                        }
                                    }} sx={{
                                minHeight: '0 !important',
                                // background: 'blue',
                                margin: 0,
                                marginY: '5px'
                              }}>{subCategory.text}</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{
                              minHeight: '0 !important',
                              paddingY: 0,
                              // paddingY: 0,
                            }}>
                              <List sx={{
                                minHeight: '0 !important',
                                // background: 'blue',
                                padding: 0,
                                paddingY: '5px',
                              }}>
                                {subCategory.items?.map((item, itemIndex) => (
                                  <ListItem key={itemIndex} >
                                    <ListItemButton onClick={() => { navigateTo(item.path); onCategoryClick(item.text) }} >
                                      <span className={`category-item my-[5px] ${activeCategory === item.text ? 'text-cta' : ''}`}>{item.text}</span>
                                    </ListItemButton>
                                  </ListItem>
                                ))}
                                {subCategory.subcategories?.map(
                                  (subcategory, subIndex) => (
                                    <ListItem key={subIndex} sx={{
                                      minHeight: '0 !important',
                                      // background: 'blue',
                                      padding: 0,
                                    }}>
                                      <ListItemButton sx={{
                                        minHeight: '0 !important',
                                        // background: 'blue',
                                        paddingY: 0,
                                      }}
                                        onClick={() => { navigateTo(subcategory.path); onCategoryClick(subcategory.nome_categoria) }}
                                      >
                                        <span className={`category-item my-[5px] ${activeCategory === subcategory.nome_categoria ? 'text-cta' : ''}`}>{subcategory.nome_categoria}</span>
                                      </ListItemButton>
                                    </ListItem>
                                  )
                                )}
                                {subCategory.sub?.map((subItem, subItemIndex) => (
                                  <ListItem key={subItemIndex} sx={{
                                    minHeight: '0 !important',
                                    // background: 'blue',
                                    padding: 0,
                                  }}>
                                    <ListItemButton sx={{
                                      minHeight: '0 !important',
                                      // background: 'blue',
                                      paddingY: 0,
                                    }}
                                      onClick={() => { navigateTo(subItem.path); onCategoryClick(subItem.text) }}
                                    >
                                      {/* <Typography>{subItem.text}</Typography> */}
                                      <span className={`category-item my-[5px] ${activeCategory === subItem.text ? 'text-cta' : ''}`}>{subItem.text}</span>
                                      {/* <Typography>{subItem.text} mini</Typography> */}
                                    </ListItemButton>
                                  </ListItem>
                                ))}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </li>
                      ) : (
                        // Altrimenti, crea un pulsante diretto
                        <ListItem key={subIndex} sx={{
                          minHeight: '0 !important',
                          // background: 'blue',
                          padding: 0,
                        }}>
                          <ListItemButton
                            onClick={() => { navigateTo(subCategory.path); onCategoryClick(subCategory.text) }}
                            sx={{
                              minHeight: '0 !important',
                              // background: 'blue',
                              paddingY: 0,
                            }}
                          >
                            <span className={`category-item my-[5px] ${activeCategory === subCategory.text ? 'text-cta' : ''}`}>{subCategory.text}</span>
                            {/* <Typography>{subCategory.text} coca</Typography> */}
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                </AccordionDetails>
              </Accordion>
            </li>
          ) : (
            <NoTitleFilters data={data} activeCategory={activeCategory} onCategoryClick={onCategoryClick} />
            // <span className="text-red-500">ciao</span>
          )}
        </React.Fragment>
      ))}
    </ul>
  );
};

export default CategoryFilters;
