import React, { useEffect, useState } from "react";
import { Box, Typography, CircularProgress, Button } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';

type SubCategoryData = {
  info: {
    attach?: any;
    id: string;
    idorder: string;
    nome_categoria: string;
    path: string;
    path_pretty?: string;
    posiz?: number;
  };
  items?: Array<{
    attach?: Array<any>;
    id: string;
    idorder: string;
    nome_categoria: string;
    path: string;
    path_pretty?: string;
    posiz?: number;
  }>;
};

type MainCategory = {
  path: string;
  main_category: string;
  sub_categories: Record<string, SubCategoryData>;
};

const pages = [
  { path: '/home', name: 'Home' },
  { path: '/prodotti/Attrezzatura Sportiva', name: 'Attrezzatura', needAuth: false, nestedPath: 'prodotti/menu/Attrezzatura Sportiva', nested: [] },
  { path: '/prodotti/Accessori', name: 'Accessori', needAuth: false, nestedPath: 'prodotti/menu/Accessori', nested: [] },
  { path: '/prodotti/abbigliamento', name: 'Abbigliamento', needAuth: false, nestedPath: 'prodotti/menu/abbigliamento', nested: [] },
  { path: '/prodotti/blor-beauty', name: 'Cosmetica', needAuth: false, nestedPath: 'prodotti/menu/blor-beauty', nested: [] },
  { path: '/prodotti/offerte', name: 'Offerte', needAuth: false, },
  { path: '/account?giftcard=1', name: 'Giftcard', needAuth: true },
  { path: '/acquisto-rapido', name: 'Acquisto Rapido', needAuth: true },
  // { path: '/giftcard', name: 'Giftcard', needAuth: false },
  { path: '/contatti', name: 'Contatti', needAuth: false },
];

const MegaMenu = ({ menuData }: { menuData: Array<MainCategory>, }) => {
  // if (loading) return <CircularProgress />;
  const [activeMainCategory, setActiveMainCategory] = useState<number | null>(null);
  const [activeSubCategory, setActiveSubCategory] = useState<number | null>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [expandedSubCategories, setExpandedSubCategories] = useState<{ [key: number]: boolean }>({});
  const [currrentPath, setCurrentPath] = useState<string>('')
  // const isExpanded = expandedKey === subIndex;

  const location = useLocation()

  const navigate = useNavigate();

  const toggleSubCategory = (subIndex: number) => {
    setExpandedSubCategories((prevState) => ({
      ...prevState,
      [subIndex]: !prevState[subIndex],
    }));
  };

  function gotToCollection(path: string) {
    navigate(path)
  }

  // function getMainMenu() {
  //   menuData.map((mainCategory, index) => {
  //     console.log(mainCategory)
  //     console.log(index)
  //   })
  // }

  // useEffect(() => {
  //   console.log(activeMainCategory)
  // }, [activeMainCategory])
  
  useEffect(() => {
    let path
    if(location.search !== ""){
      path = `${location.pathname.replace(/%20/g, " ")}${location.search}`;
    } else {
      path = location.pathname.replace(/%20/g, " ");
    }
    setCurrentPath(path)
    console.log('el linko', path)
  }, [location])

  return (
    <div className="menu-container relative hidden lg:flex w-full h-full">
      {/* Menu principale */}
      <ul className="main-menu flex w-full h-full items-center justify-center text-white">
        <li>
          <Button className={`${currrentPath === `/home` ? '!text-cta' : ''}`} href={'/home'}
            sx={{
              display: 'flex',
              color: '#fff',
              fontSize: '14px',
              // backgroundColor: 'red',
              height: '100%',
              '&:hover': {
                color: '#FFCE00',
              },
            }}
          >
            Home
          </Button>
        </li>
        <li>
          <Button className={`${currrentPath === `/chi-siamo` ? '!text-cta' : ''}`} href={'/chi-siamo'}
            sx={{
              display: 'flex',
              color: '#fff',
              fontSize: '14px',
              // backgroundColor: 'red',
              height: '100%',
              '&:hover': {
                color: '#FFCE00',
              },
            }}
          >
            Chi siamo
          </Button>
        </li>
        {menuData.map((mainCategory, index) => (
          <li
            key={index}
            className={`main-menu-item ${activeMainCategory === index ? 'active' : ''} h-full`}
            onMouseEnter={() => { setActiveMainCategory(index) }}
            onMouseLeave={() => setActiveMainCategory(null)}
          >
            <Button className={`${currrentPath === `/prodotti/${mainCategory.path}` ? '!text-cta' : ''}`} href={`/prodotti/${mainCategory.path}`}
              sx={{
                display: 'flex',
                color: '#fff',
                fontSize: '14px',
                // backgroundColor: 'red',
                height: '100%',
                '&:hover': {
                  color: '#FFCE00',
                },
              }}
            >
              {mainCategory.main_category}
            </Button>
            {/* <span onClick={() => gotToCollection(`/prodotti/${mainCategory.path}`)}>{mainCategory.main_category}</span> */}
            {/* <Link to={`/prodotti/${mainCategory.path}`}>
                        {mainCategory.main_category}
                    </Link> */}

            {/* Sottocategorie */}
            {activeMainCategory === index && mainCategory.sub_categories && (
              <div className="sub-menu py-4 w-screen max-h-[calc(100vh-100px)] overflow-y-auto flex justify-center backdrop-blur-xl bg-black/80 absolute z-20 left-[-120px] top-[99px]">
                <ul className="grid grid-cols-7 grid-wrap container-boxed w-full gap-4">
                  {Object.entries(mainCategory.sub_categories).map(
                    ([subCategoryName, subCategoryData], subIndex) => (
                      <li key={subIndex} className="sub-menu-item w-full text-white col-span-1 p-4">
                        <a
                          href={`/prodotti/${subCategoryData.info.path}`}
                          className={`${currrentPath === `/prodotti/${subCategoryData.info.path}` ? '!text-cta' : ''} font-bold text-base hover:text-cta`}
                        >
                          {subCategoryName}
                        </a>
                        <span className="flex h-[2px] my-2 bg-white"></span>
                        {/* Categorie della sottocategoria */}
                        <ul className="sub-sub-menu w-full">
                          {subCategoryData.items?.slice(0, expandedSubCategories[subIndex] ? subCategoryData.items.length : 5).map((item, itemIndex) => (
                            <li key={itemIndex} className="sub-sub-menu-item w-full mt-2">
                              <a
                                href={`/prodotti/${item.path}`}
                                className={`${currrentPath === `/prodotti/${item.path}` ? '!text-cta' : ''} text-base hover:text-cta`}
                              >
                                {item.nome_categoria}
                              </a>
                            </li>
                          ))}
                          {Array.isArray(subCategoryData.items) && subCategoryData.items.length > 5 && (
                            <li className="sub-sub-menu-item w-full mt-2">
                            <button
                              onClick={() => toggleSubCategory(subIndex)}
                              className="text-base text-cta underline uppercase"
                            >
                              {expandedSubCategories[subIndex] ? "Mostra meno" : "Vedi tutti"}
                            </button>
                          </li>
                          )}
                          {/* {isExpanded && (
                            <li className="sub-sub-menu-item w-full mt-2">
                              <button
                                onClick={() => setIsExpanded(false)}
                                className="text-base text-cta underline"
                              >
                                Mostra meno
                              </button>
                            </li>
                          )} */}
                        </ul>
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}
          </li>
        ))}
        <li>
          <Button href={'/account?giftcard=1'} className={`${currrentPath === `/account?giftcard=1` ? '!text-cta' : ''}`}
            sx={{
              display: 'flex',
              color: '#fff',
              fontSize: '14px',
              // backgroundColor: 'red',
              height: '100%',
              '&:hover': {
                color: '#FFCE00',
              },
            }}
          >
            Giftcard
          </Button>
        </li>
        <li>
          <Button href={'/contatti'} className={`${currrentPath === `/contatti` ? '!text-cta' : ''}`}
            sx={{
              display: 'flex',
              color: '#fff',
              fontSize: '14px',
              // backgroundColor: 'red',
              height: '100%',
              '&:hover': {
                color: '#FFCE00',
              },
            }}
          >
            Contatti
          </Button>
        </li>
      </ul>
    </div>
  );
};

export default MegaMenu;
