import "./color-picker.scss";
import React from "react";

import { Box, Button } from "@mui/material";

export const ColorPicker = (props: { colors: Array<any>, selected: number, loading: boolean, click: (id: number) => void }) => {

  return (
    <React.Fragment>
      {props.colors.map((color: any) => {
        return (
          <div className={`flex min-w-[35px] w-[35px] h-[35px] md:w-[40px] md:h-[40px] md:min-w-[40px] aspect-square rounded-full overflow-x-hidden cursor-pointer ${ props.selected === color.id ? 'border-[3px] border-cta' : 'border-[3px] border-secondary' }`}>
            <div className="flex w-full h-full" onClick={() => props.click(color.id)}>
              <img className="w-full h-full object-cover object-center" src={color.images?.[0]} alt="" />
              {/* TODO - far apparire tendina con il nome */}
            </div>

            {/* <Box sx={{
              display: 'inline-flex',
              flexDirection: 'column',
              width: 'fit-content',
              alignItems: 'center',
              marginRight: '10px',
              marginBottom: '10px',
            }}>
              <Button
                key={color.id}
                disabled={props.loading}
                onClick={() => props.click(color.id)}
                sx={{
                  padding: '0',
                  border: props.selected === color.id ? '2px solid #FFCE00' : '',
                  opacity: !props.loading ? 1 : 0.5,
                }}>
                <img width="40px" src={color.images?.[0]} alt="" />
              </Button>
              <span style={{
                marginTop: 1,
                color: props.selected === color.id ? '#FFCE00' : '#fff',
              }}>
                {color.valore}
              </span>
            </Box> */}
          </div>
        );
      })}
    </React.Fragment>
  );
}
