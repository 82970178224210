import React, { useEffect, useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

type SubCategoryData = {
  info: {
    attach?: any;
    id: string;
    idorder: string;
    nome_categoria: string;
    path: string;
    path_pretty?: string;
    posiz?: number;
  };
  items?: Array<{
    attach?: Array<any>;
    id: string;
    idorder: string;
    nome_categoria: string;
    path: string;
    path_pretty?: string;
    posiz?: number;
  }>;
};

type MainCategory = {
  path: string;
  main_category: string;
  sub_categories: Record<string, SubCategoryData>;
};

const MobileMenu = ({ pages }: { pages: Array<MainCategory> }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [currrentPath, setCurrentPath] = useState<string>('')

  const navigate = useNavigate();
  const location = useLocation();

  const toggleDrawer = (open: boolean) => () => {
    setMenuOpen(open);
  };

  const goTo = (path: string) => {
    window.location.assign(`/prodotti/${path}`);
    setMenuOpen(false); 
  };

  useEffect(() => {
    let path
    if(location.search !== ""){
      path = `${location.pathname.replace(/%20/g, " ")}${location.search}`;
    } else {
      path = location.pathname.replace(/%20/g, " ");
    }
    setCurrentPath(path)
    console.log('el linko', path)
  }, [location])

  const renderMenuItems = (items: MainCategory[]) => (
    <>
      <li className="p-0 my-[5px] flex items-center">
        <Button href={'/home'} className={`${currrentPath === `/home` ? '!text-cta' : ''}`}
          sx={{
            display: 'flex',
            justifyContent: 'start',
            padding: 0,
            fontWeight: 400,
            color: '#fff',
            fontSize: '16px',
            height: '100%',
            '&:hover': {
              color: '#FFCE00',
            },
          }}
        >
          Home
        </Button>
      </li>
      <li className="p-0 flex items-center">
        <Button href={'/chi-siamo'} className={`${currrentPath === `/chi-siamo` ? '!text-cta' : ''}`}
          sx={{
            display: 'flex',
            justifyContent: 'start',
            padding: 0,
            fontWeight: 400,
            color: '#fff',
            fontSize: '16px',
            height: '100%',
            '&:hover': {
              color: '#FFCE00',
            },
          }}
        >
          Chi siamo
        </Button>
      </li>
      {items.map((mainCategory, index) => (
        <li key={index}>
          {mainCategory.main_category ? (
            <Accordion sx={{boxShadow: 'none'}} className="!bg-transparent !p-0">
              <AccordionSummary
                className="!text-white hover:!text-cta !px-0 !min-h-0"
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${index}-content`}
                id={`panel-${index}-header`}
              >
                <Typography className={`${currrentPath === `/prodotti/${mainCategory.path}` ? '!text-cta' : ''}`} onClick={() => goTo(mainCategory.path)}>{mainCategory.main_category}</Typography>
              </AccordionSummary>
              <AccordionDetails className="!pe-0 !py-0 !min-h-0">
                <List>
                  {Object.entries(mainCategory.sub_categories).map(([name, subCategoryData], nestedIndex) => (
                    <li className="my-[5px]">
                    <Accordion sx={{boxShadow: 'none'}} key={nestedIndex} className="!bg-transparent !p-0">
                      <AccordionSummary className="!text-white hover:!text-cta !px-0 !min-h-0"
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`sub-panel-${nestedIndex}-content`}
                        id={`sub-panel-${nestedIndex}-header`}
                      >
                        <Typography className={`my-[5px] ${currrentPath === `/prodotti/${subCategoryData.info.path}` ? '!text-cta' : ''}`} onClick={() => goTo(subCategoryData.info.path)}>{name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails className="!pe-0 !py-0 !min-h-0">
                        <List>
                          {subCategoryData.items?.map((item, itemIndex) => (
                            <li className="text-white hover:!text-cta cursor-pointer capitalize" key={itemIndex} onClick={() => goTo(item.path)}>
                              <p className={`my-[5px] ${currrentPath === `/prodotti/${item.path}` ? '!text-cta' : ''}`}>{item.nome_categoria}</p>
                              {/* {item.path} */}
                            </li>
                          ))}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                    </li>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ) : (
            <ListItem>
              {/* <ListItemText primary={page.name} /> */}
            </ListItem>
          )}
        </li>
      ))}
      {/* Pulsante Contatti */}
      <li className="p-0 flex items-center">
        <Button href={'/account?giftcard=1'} className={`${currrentPath === `/account?giftcard=1` ? '!text-cta' : ''}`}
          sx={{
            display: 'flex',
            justifyContent: 'start',
            padding: 0,
            fontWeight: 400,
            color: '#fff',
            fontSize: '16px',
            height: '100%',
            '&:hover': {
              color: '#FFCE00',
            },
          }}
        >
          Giftcard
        </Button>
      </li>
      <li className="p-0 flex items-center">
        <Button
          href={"/contatti"} className={`${currrentPath === `/contatti` ? '!text-cta' : ''}`}
          sx={{
            display: 'flex',
            justifyContent: 'start',
            padding: 0,
            fontWeight: 400,
            color: '#fff',
            fontSize: '16px',
            height: '100%',
            '&:hover': {
              color: '#FFCE00',
            },
          }}
        >
          Contatti
        </Button>
      </li>
    </>
  );


  return (
    <>
      <IconButton sx={{
        padding: 0
      }}
        className="!text-white"
        edge="start"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer className="mobile-drawer" anchor="right" open={menuOpen} onClose={toggleDrawer(false)}>
        {/* <pre>{JSON.stringify(pages, null, 2)}</pre> */}
        <div className="flex flex-col p-[20px] min-h-full !bg-secondary text-white w-[350px] text-uppercase">
          <span className="flex w-full justify-end">
            <button onClick={toggleDrawer(false)} className="flex w-[30px] bg-black rounded-full items-center justify-center aspect-square group">
              <CloseIcon className="group-hover:text-cta" />
              </button>
          </span>
          <ul className="flex flex-col gap-y-[5px]">
            {renderMenuItems(pages)}
          </ul>
        </div>
      </Drawer>
    </>
  );
};

export default MobileMenu;
